import { render, staticRenderFns } from "./sector.vue?vue&type=template&id=b1ac5afc"
import script from "./sector.vue?vue&type=script&lang=js"
export * from "./sector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3084281448/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b1ac5afc')) {
      api.createRecord('b1ac5afc', component.options)
    } else {
      api.reload('b1ac5afc', component.options)
    }
    module.hot.accept("./sector.vue?vue&type=template&id=b1ac5afc", function () {
      api.rerender('b1ac5afc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sectors/sector.vue"
export default component.exports